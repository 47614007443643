<template>
  <div class="mcf-regional" :style="{ 'mcf-regional--disabled': !shippingLoaded }">
    <ACheckboxToggle
      :value="isFilterActive"
      :title="availableTodayFilterLabel"
      :read-only="!isSetFilterAvailable"
      @input="handleFilterChange"
      @click.native="availableTodayGuard"
    />
  </div>
</template>

<script>
import ACheckboxToggle from 'theme/components/atoms/a-checkbox-toggle';
import { attributes, products } from 'config';
import { mapState } from 'vuex';
import ShippingControlsMixin from '$modules/shipping/mixins/shipping-controls-mixin';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { newPostMethods } from '$modules/shipping/config';

export default {
  name: 'MCategoryFilterRegional',
  mixins: [ShippingControlsMixin],
  components: {
    ACheckboxToggle
  },
  data () {
    return {
      waiting: false
    }
  },
  computed: {
    ...mapState({
      currentShipping: state => state['shipping-module'].current,
      shippingLoaded: state => state['shipping-module'].loaded.shipping,
      currentRegion: state => state['shipping-module'].currentRegion
    }),
    availableTodayFilter () {
      return attributes.filterAvailableToday;
    },
    availableTodayFilterLabel () {
      return this.$t(attributes.filterAvailableToday.label);
    },
    availableTodayFilterValues () {
      return products.availableTodayFilterValues;
    },
    isFilterActive () {
      return this.$route.query.hasOwnProperty(this.availableTodayFilter.type);
    },
    isCurrentNewPost () {
      return newPostMethods.includes(this.currentShipping?.method)
    },
    isSetFilterAvailable () {
      return (
        this.currentShipping.type &&
        this.currentRegion?.id &&
        !this.isCurrentNewPost &&
        this.shippingLoaded
      )
    }
  },
  methods: {
    availableTodayGuard (e) {
      if (this.isSetFilterAvailable) return

      e.stopPropagation()
      e.preventDefault()

      this.$emit('close-filter')

      this.openShippingModal({
        availableFilter: 'true'
      })

      this.listenEvent()
    },
    listenEvent () {
      if (this.waiting) return

      this.waiting = false

      return EventBus.$once(`set-available-filter`, (data) => {
        data && this.handleFilterChange(data)

        this.waiting = false
      });
    },
    async handleFilterChange (value) {
      if (!this.isSetFilterAvailable) return

      const filter = {
        type: this.availableTodayFilter.type,
        id: this.availableTodayFilterValues[value]
      }

      this.$emit('stock-filter-changed', filter);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.mcf-regional {
  display: flex;
  margin-right: var(--spacer-sm);

  @include for-mobile {
    position: relative;
    margin: var(--filter-stock-margin, 0);
  }

  &--disable {
    pointer-events: none;
    opacity: 0.7;
  }
}
</style>
