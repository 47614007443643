<template>
  <section
    class="m-seo-block"
    ref="lazy"
    :class="isContentLarge ? 'm-seo-block--long' : 'm-seo-block--short'"
    v-if="seoHtmlContent"
  >
    <LazyHydrate when-idle>
      <div
        class="m-seo-block__content"
        :class="{ 'm-seo-block__content--collapsed': isContentLarge && !isShowMore }"
        ref="content"
        :style="computedStyle"
        v-html="seoHtmlDecoded"
      />
    </LazyHydrate>

    <SfButton
      class="m-seo-block__link sf-link sf-link--primary sf-button--text"
      data-transaction-name="SB - Show More"
      @click="showMore"
      v-if="isContentLarge"
    >
      {{ showMoreText }}
    </SfButton>
  </section>
</template>

<script>
import {
  SfButton
} from '@storefront-ui/vue';
import { htmlDecode } from '@vue-storefront/core/filters';
import { safeHtml } from 'theme/helpers/safe-html';
import LazyHydrate from 'vue-lazy-hydration';

const MAX_HEIGHT_SEO_TEXT = 190; // px

export default {
  name: 'MSeoBlock',
  components: {
    SfButton,
    LazyHydrate
  },
  props: {
    seoHtml: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isContentLarge: false,
      isShowMore: true,
      content: null,
      seoTextHeight: MAX_HEIGHT_SEO_TEXT
    }
  },
  computed: {
    seoHtmlContent () {
      return this.seoHtml || ''
    },
    showMoreText () {
      return this.isShowMore ? this.$t('Hide') : this.$t('Read all');
    },
    seoHtmlDecoded () {
      return safeHtml(htmlDecode(this.seoHtmlContent));
    },
    computedStyle () {
      return !this.isShowMore ? { maxHeight: MAX_HEIGHT_SEO_TEXT + 'px' } : {};
    }
  },
  methods: {
    getHtmlFromTag (content) {
      if (!content) {
        return
      }

      this.content = content.innerHTML
    },
    showMore () {
      this.$emit('click:seo-more');
      this.isShowMore = !this.isShowMore;
    },
    calculateHeight (content) {
      if (content) {
        this.isContentLarge = content.offsetHeight >= MAX_HEIGHT_SEO_TEXT;
      }
      this.isShowMore = true;
    },
    getContentElement () {
      if (!this.$refs.lazy) return
      const el = this.$refs.lazy

      if (!el || !el.querySelector) return

      return el.querySelector('.m-seo-block__content')
    }
  },
  mounted () {
    this.$nextTick(() => {
      const content = this.getContentElement()

      if (!content) return

      this.getHtmlFromTag(content)
      this.calculateHeight(content);
    })
  },
  watch: {
    seoHtmlDecoded () {
      this.$nextTick(() => {
        const content = this.getContentElement()
        this.calculateHeight(content)
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.m-seo-block {
  padding-bottom: var(--spacer-50);

  @include for-tablet {
    padding-bottom: var(--spacer-60);
  }

  &--short {
    @include for-desktop {
      padding-bottom: 0;
    }
  }

  &--long {
    @include for-desktop {
      padding-bottom: var(--spacer-100);
    }
  }

  &__content {
    overflow: hidden;
    position: relative;

    ::v-deep {
      a {
        color: var(--orange);
      }

      b {
        font-weight: var(--font-semibold);
      }

      ul {
        padding: 0;
        list-style: none;

        li {
          display: flex;
          align-items: baseline;

          &::before {
            content: "•";
            display: inline-block;
            color: var(--orange);
            padding-right: px2rem(10);
            font-size: px2rem(20);
          }
        }
      }

      ul.list-style-decimal {
        li {
          display: list-item;
          padding-bottom: 8px;

          &::before {
            content: counter(list-item) ".";
            width: px2rem(10);
            font-size: var(--font-size-14);
            font-weight: 700;
          }
        }
      }

      * {
        color: var(--black);
        font-size: var(--font-sm);
      }

      h1 {
        font-size: var(--font-size-32);
      }

      h2, h3 {
        font-size: var(--font-size-24);
        font-weight: var(--font-medium);
        color: var(--black);

        &:first-child {
          margin-top: 0;
        }

        strong {
          font-weight: var(--font-medium);
        }
      }
    }

    &--collapsed {
      &::after {
        background: linear-gradient(360deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        bottom: 0;
        content: '';
        left: 0;
        height: var(--spacer-40);
        position: absolute;
        right: 0;
      }
    }
  }

  &__link {
    margin-top: var(--spacer-15);

    --button-font-size: var(--font-sm);
    --button-font-weight: var(--font-normal);
    --button-background: transparent;
    --button-text-decoration: none;
  }
}
</style>
