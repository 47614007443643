<template>
  <div class="m-category-np">
    <div class="m-category-np__logo">
      <span class="m-category-np__icon"></span>
    </div>
    <div class="m-category-np__wrapper">
      <span class="m-category-np__title">
        {{ $t('NP notice description') }}

        <template>
          {{ $t(cityName) }}
        </template>
      </span>

      <span class="m-category-np__actions">
        <SfButton
          class="sf-button--primary m-category-np__button"
          data-transaction-name="Change shipping - NP"
          @click="openShippingModal"
        >
          {{ $t('Check availability') }}
        </SfButton>
      </span>
    </div>
  </div>
</template>
<script>
import ShippingControlsMixin from '$modules/shipping/mixins/shipping-controls-mixin';
import { SfButton } from '@storefront-ui/vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'MCategoryNP',
  components: {
    SfButton
  },
  mixins: [ShippingControlsMixin],
  props: {
    isProduct: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapGetters({
      isNewPost: 'shipping-module/isCurrentNewPost'
    }),
    ...mapState({
      currentRegion: state => state['shipping-module'].currentRegion
    }),
    cityName () {
      if (this.isNewPost) {
        return this.$t('NP notice description - empty')
      }

      const cityName = this.currentRegion?.name

      return cityName ? `${this.$t('In city')} ${cityName}` : this.$t('NP notice description - empty')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.m-category-np {
  display: flex;
  gap: var(--spacer-25);
  padding: var(--spacer-12) var(--spacer-25);
  background: var(--yellow-light-grayish);

  &__button {
    display: contents;
    cursor: pointer;
    color: var(--orange);
    transition: 200ms;
    font-size: var(--font-size-14);
    text-align: left;

    &:hover {
      color: var(--orange-hover);
    }

    //padding: var(--spacer-8) var(--spacer-16);
  }

  &__logo {
    flex: 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    @include font-icon(var(--icon-info));

    &:before {
      font-size: var(--font-size-40);
      color: var(--orange);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-5);
  }

  &__title, &__description {
    display: block;
    font-size: var(--font-size-14);
  }

  &__title {
    color: var(--black);
  }

  &__description {
    color: var(--dark-gray);
  }
}
</style>
