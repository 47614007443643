<template>
  <div class="mp-labels">
    <pre v-if="sqpp" class="mp-label mp-label--debug">{{ sqpp.availability }}</pre>
    <span v-if="productLabel" class="mp-label" :class="{ 'mp-label--light': light }">
      {{ $t(productLabel) }}
    </span>
  </div>
</template>
<script>

export default {
  name: 'MProductLabels',
  props: {
    sqpp: {
      type: Object,
      default: () => ({})
    },
    productLabel: {
      type: String,
      default: () => ''
    },
    light: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.mp-labels {
  flex: 1;
  gap: var(--spacer-5);
  display: block;
}

.mp-label {
  display: inline-block;
  background-color: var(--orange-light-background);
  border-radius: var(--spacer-5);
  padding: var(--spacer-4) var(--spacer-8);
  position: relative;
  font-weight: var(--font-normal);
  font-size: var(--font-size-12);
  line-height: var(--font-size-18);
  margin-bottom: var(--spacer-5);
  margin-right: var(--spacer-5);

  &--light {
    background-color: var(--light-gray);
    color: var(--dark-gray);
  }

  &--debug {
    display: none;
    background-color: var(--light-gray);
    color: var(--dark-gray);
    font-size: var(--font-size-12);
    line-height: initial;
  }
}
</style>
