<template>
  <div
    v-if="regionList.length > 0"
    class="c-mr"
  >
    <div class="c-mr__title">
      <a
        v-if="currentRegion.id"
        :href="link"
        @click.stop.prevent="goToMain"
      >
        {{ label }}
      </a>
      <template v-else>{{ label }}</template>

      {{ $t('Popular Search Query') }}
    </div>

    <div class="c-mr__list">
      <a
        v-for="item in regionList"
        :key="item.id"
        :href="item.url"
        class="c-mr__link"
        @click.stop.prevent="goToPage(item)"
      >
        {{ item.name }}
      </a>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  getCurrentInstance
} from 'vue';

import { HumanFilter } from 'theme/helpers/humanFilter';
import { clearRegionUrl } from '$modules/shipping/utils/region-url';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export default defineComponent({
  name: 'MCategoryRegion',
  components: {
  },
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  setup () {
    const { proxy: vm } = getCurrentInstance();

    const getCurrentRegion = computed(() => vm.$store.state['shipping-module'].currentRegion);
    const getRegionList = computed(() => vm.$store.getters['shipping-module/getRegionList']);

    const hf = computed(() => (
      clearRegionUrl(
        new HumanFilter(vm.$route.fullPath).getSlug(true),
        getCurrentRegion.value
      ) || ''
    ))

    const regionList = computed(() => {
      return (getRegionList?.value || [])
        .filter(i => i.is_top)
        .map(i => ({
          id: i.id,
          name: i.name,
          url: localizedRoute(`/${i.url_key}${hf.value}`)
        }))
    });

    const goToPage = (region) => {
      vm.$store.dispatch('shipping-module/clearShipping')

      const currentRegion = getCurrentRegion.value?.id !== region.id
        ? getRegionList.value.find(i => i.id === region.id)
        : null

      vm.$store.dispatch('shipping-module/setCurrentRegion', currentRegion)

      window.scrollTo(0, 0);
    }

    const goToMain = () => {
      vm.$store.dispatch('shipping-module/clearShipping')
      vm.$store.dispatch('shipping-module/setCurrentRegion', null)

      window.scrollTo(0, 0);
    }

    return {
      link: hf?.value || '/',
      currentRegion: getCurrentRegion,
      regionList,
      goToMain,
      goToPage
    };
  }
});
</script>

<style lang="scss">
@import "~theme/css/breakpoints";
@import "~theme/css/mixins";
@import "~theme/css/px2rem";

.c-mr {
  border: var(--spacer-1) solid var(--light-gray);
  padding: var(--spacer-10) var(--spacer-10);

  &__title {
    @include header-title;
    margin-top: 0;
    margin-bottom: var(--font-size-25);

    @include for-mobile {
      margin-top: 0;
      margin-bottom: var(--font-size-25);
    }
  }

  &__list {
    margin-bottom: 0;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: var(--spacer-10);

    @include for-desktop {
      grid-template-columns: auto auto auto auto auto;
      grid-gap: var(--spacer-15);
    }
  }

  &__link {
    color: var(--black);
    font-size: var(--font-sm);
    transition: color 200ms;

    &:visited {
      color: var(--black);
    }

    &:hover {
      color: var(--orange-hover);
    }
  }
}
</style>
